<template>
  <div>
    <div
      v-if="this.$i18n.locale == 'ar'"
      class="navbar-container main-menu-content d-flex justify-content-end"
    >
      <horizontal-nav-menu-items :items="nav" />
    </div>
    <div
      v-if="this.$i18n.locale == 'fr'"
      class="navbar-container main-menu-content"
    >
      <horizontal-nav-menu-items :items="nav" />
    </div>
    <div
      v-if="this.$i18n.locale == 'en'"
      class="navbar-container main-menu-content"
    >
      <horizontal-nav-menu-items :items="nav" />
    </div>
  </div>

</template>

<script>
import navMenuItems from '@/navigation/horizontal/lead'
import navAdmin from '@/navigation/horizontal/admin'
import store from '@/store/store'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {
    }
  },
  computed: {
    nav() {
      if (store.state.role === false) {
        return [
          {
            title: this.$i18n.t('Insc'),
            route: 'demande',
            icon: 'HomeIcon',
          },
        ]
      }
      return navAdmin
    },
  },
  created() {
    // if (store.state.role === false) {
    //   this.nav = [
    //     {
    //       title: this.$i18n.t('Resevation'),
    //       route: 'demande',
    //       icon: 'HomeIcon',
    //     },
    //     {
    //       title: 'Nos Ecole',
    //       route: 'dashboard',
    //       icon: 'FileIcon',
    //     },
    //   ]
    // } else {
    //   this.nav = navAdmin
    // }
  },
  setup() {
    return {
      navMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
