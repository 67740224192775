export default [

  {
    title: 'Demande Inscription',
    route: 'demande',
    icon: 'HomeIcon',
  },
  {
    title: 'Nos Ecole',
    route: 'dashboard',
    icon: 'FileIcon',
  },
]
